<template>
  <div>
    aktualne hasło: {{ password }}
    <p>
      <input
        type="text"
        v-model="passwordField"
        class="form-control"
      /><br /><br />
      <button class="btn btn-danger" @click="setPassword">Zapisz</button>
    </p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Password',
  components: {},
  data() {
    return {
      passwordField: ''
    }
  },
  computed: {
    password() {
      return localStorage.getItem('app-password') || 'brak'
    }
  },
  methods: {
    setPassword() {
      console.log('saving in local storage', this.passwordField)
      localStorage.setItem('app-password', this.passwordField)
      window.location.reload()
    }
  }
}
</script>

<style lang="sass" scoped></style>
