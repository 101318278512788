var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._v(" aktualne hasło: " + _vm._s(_vm.password) + " "), _c('p', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.passwordField,
      expression: "passwordField"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.passwordField
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.passwordField = $event.target.value;
      }
    }
  }), _c('br'), _c('br'), _c('button', {
    staticClass: "btn btn-danger",
    on: {
      "click": _vm.setPassword
    }
  }, [_vm._v("Zapisz")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }